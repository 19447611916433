import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BroadcastService} from '../../../../../services/bradcast.services';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() element: any;
    @Output() elementChangeHandler: EventEmitter<any> = new EventEmitter();

    actions: any;
    fontFamilies: any;
    state: boolean;
    constructor(private broadcastService: BroadcastService) {
    }

    ngOnInit(): void {
        this.actions = [
            {label: 'Link', value: 'link'},
            {label: 'Mostrar/Ocultar ID', value: 'showOrHide'}
        ];
        this.state = false;
        this.element.action = this.element.action ? this.element.action : {};
        this.element.action.type = this.element.action.type ? this.element.action.type : '';
        this.fontFamilies = [
            {
                name: 'HPSimplified',
                value: 'HPSimplified'
            },
            {
                name: 'HPSimplifiedLight',
                value: 'HPSimplifiedLight'
            },
            {
                name: 'FormaDJRCyrillic',
                value: 'FormaDJRCyrillic'
            },
            {
                name: 'FormaDJRCyrillicLight',
                value: 'FormaDJRCyrillicLight'
            },
            {
                name: 'MicrosoftSegoeUi',
                value: 'MicrosoftSegoeUi'
            },
            {
                name: 'MicrosoftSegoeUiLight',
                value: 'MicrosoftSegoeUiLight'
            },
            {
                name: 'MicrosoftSegoeText',
                value: 'MicrosoftSegoeText'
            },
            {
                name: 'MicrosoftSegoeBold',
                value: 'MicrosoftSegoeBold'
            },
            {
                name: 'MideaGothanBookItalic',
                value: 'MideaGothanBookItalic'
            },
            {
                name: 'MideaGothanLight',
                value: 'MideaGothanLight'
            },
            {
                name: 'MideaGothanBookText',
                value: 'MideaGothanBookText'
            },
            {
                name: 'MideaGothanboldBoldtitle',
                value: 'MideaGothanboldBoldtitle'
            },
            {
                name: 'OsterHelveticaItalic',
                value: 'OsterHelveticaItalic'
            },
            {
                name: 'OsterHelveticaLight',
                value: 'OsterHelveticaLight'
            },
            {
                name: 'OsterHelveticaText',
                value: 'OsterHelveticaText'
            },
            {
                name: 'OsterHelveticaTextBold',
                value: 'OsterHelveticaTextBold'
            },
            {
                name: 'HelveticaNeue',
                value: 'HelveticaNeue'
            },
            {
                name: 'KorolevBold',
                value: 'KorolevBold'
            },
            {
                name: 'VeetDisplay',
                value: 'VeetDisplay'
            },
            {
                name: 'One-Night-Sans-Black',
                value: 'One-Night-Sans-Black'
            },
            {
                name: 'OneNightSans-Condensed',
                value: 'OneNightSans-Condensed'
            },
            {
                name: 'OneNightSans-Extended',
                value: 'OneNightSans-Extended'
            },
            {
                name: 'OneNightSans-ExtendedBlack',
                value: 'OneNightSans-ExtendedBlack'
            },
            {
                name: 'QuatroSlab-Bold',
                value: 'QuatroSlab-Bold'
            },
            {
                name: 'QuatroSlab-Regular',
                value: 'QuatroSlab-Regular'
            },
            {
                name: 'Helvetica',
                value: 'Helvetica'
            },
            {
                name: 'OpenSans-Bold',
                value: 'OpenSans-Bold'
            },   
            {
                name: 'CalpsSans-Regular',
                value: 'CalpsSans-Regular'
            },
            {
                name: 'CalpsSansSlim-Medium',
                value: 'CalpsSansSlim-Medium'
            },
            {
                name: 'CalpsSansSlim-Regular',
                value: 'CalpsSansSlim-Regular'
            },   
            {
                name: 'Lithos-Pro-Black',
                value: 'Lithos-Pro-Black'
            },
            {
                name: 'Optima',
                value: 'Optima'
            },
            {
                name: 'Optima-B',
                value: 'Optima-B'
            },
            {
                name: 'Optima-Medium',
                value: 'Optima-Medium'
            },
            {
                name: 'Optima-Italic',
                value: 'Optima-Italic'
            },
            {
                name: 'Inter-18pt-Medium',
                value: 'Inter-18pt-Medium'
            },
            {
                name: 'Inter-24pt-Light',
                value: 'Inter-24pt-Light'
            },
            {
                name: 'Gavisans-Bold',
                value: 'Gavisans-Bold'
            },
            {
                name: 'Gavisans-BoldItalic',
                value: 'Gavisans-BoldItalic'
            },
            {
                name: 'Gavisans-Regular',
                value: 'Gavisans-Regular'
            },
            {
                name:'ELECTROLUX-Durotype-Flexo-Bold',
                value:'ELECTROLUX-Durotype-Flexo-Bold'
            },
            {
                name:'ELECTROLUX-Durotype-Flexo-Medium',
                value:'ELECTROLUX-Durotype-Flexo-Medium'
            },
            {
                name:'ELECTROLUX-Durotype-Flexo-Regular',
                value:'ELECTROLUX-Durotype-Flexo-Regular'
            },
            {
                name:'ELECTROLUX-Sans-2-Light-1',
                value:'ELECTROLUX-Sans-2-Light-1'
            },
            {
                name:'ELECTROLUX-Sans-2-Light',
                value:'ELECTROLUX-Sans-2-Light'
            },
            {
                name:'ELECTROLUX-Sans-5-Bold',
                value:'ELECTROLUX-Sans-5-Bold'
            },
            {
                name:'ELECTROLUX-Flexo-Demi',
                value:'ELECTROLUX-Flexo-Demi'
            },
            {
                name:'ELECTROLUX-FontsFree-Net-Proxima-Nova-Black-2',
                value:'ELECTROLUX-FontsFree-Net-Proxima-Nova-Black-2'
            },
            {
                name:'PROXIMANOVA-SEMIBOLD',
                value:'PROXIMANOVA-SEMIBOLD'
            },
            {
                name:'PROXIMANOVA-SEMIBOLDITALIC',
                value:'PROXIMANOVA-SEMIBOLDITALIC'
            },
            {
                name:'PROXIMANOVA-BOLDIT',
                value:'PROXIMANOVA-BOLDIT'
            },
            {
                name:'PROXIMANOVA-LIGHTITALIC',
                value:'PROXIMANOVA-LIGHTITALIC'
            },
            {
                name:'PROXIMANOVA-REGULARITALIC',
                value:'PROXIMANOVA-REGULARITALIC'
            },
            {
                name:'PROXIMANOVA-BLACK',
                value:'PROXIMANOVA-BLACK'
            },
            {
                name:'PROXIMANOVA-BOLD',
                value:'PROXIMANOVA-BOLD'
            },
            {
                name:'PROXIMANOVA-EXTRABOLD',
                value:'PROXIMANOVA-EXTRABOLD'
            },
            {
                name:'PROXIMANOVA-LIGHT',
                value:'PROXIMANOVA-LIGHT'
            },
            {
                name:'PROXIMANOVA-REGULAR',
                value:'PROXIMANOVA-REGULAR'
            },
            {
                name:'PROXIMANOVA-REG-WEBFONT',
                value:'PROXIMANOVA-REG-WEBFONT'
            },
            {
                name:'BLIK-BebasNeue-Bold',
                value:'BLIK-BebasNeue-Bold'
            },
            {
                name:'BLIK-Gotham-Bold',
                value:'BLIK-Gotham-Bold'
            },
            {
                name:'BLIK-Gotham-Book',
                value:'BLIK-Gotham-Book'
            },
            {
                name:'BLIK-Futura-Book',
                value:'BLIK-Futura-Book'
            },
            {
                name:'BLIK-Futura-Bold',
                value:'BLIK-Futura-Bold'
            },
            {
                name:'BLIK-Futura-ExtraBold',
                value:'BLIK-Futura-ExtraBold'
            }
        ]
    }


}
